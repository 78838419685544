import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import '../stylesheets/NavigationSidebar.scss';
import {
  selectors as categorySelectors,
  selectCategory,
} from '../ducks/categories';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SelectCategoryLink from './SelectCategoryLink';
import TeamLogo from './TeamLogo';
import HomeTurfLogo from '../../assets/images/logo-small.png';
import { playMedia } from '../ducks/media';
import { useGameState } from '../ducks/gameInstance';

const NavigationSidebar = () => {
  const dispatch = useDispatch();
  const categories = useSelector(categorySelectors.selectAll);
  const globalCategories = categories.filter(c => c.global).sort();
  const teamCategories = categories
    .filter(c => !c.global)
    .sort(function (a, b) {
      return a.sort_order - b.sort_order;
    });
  const sortedCategories = [...teamCategories, ...globalCategories];
  const gameState = useGameState();

  if (gameState === 'pre-game' || gameState === 'post-game') {
    window.open(`${window.location.origin}/admin`, '_self');
  }

  return (
    <div className="sidebar">
      <div className="dashboard-details">
        {gameState === 'test' && <h2 style={{ color: 'red' }}>TEST MODE</h2>}
        {gameState === 'live' && (
          <h2 className="pulsate" style={{ color: 'green' }}>
            LIVE
          </h2>
        )}
        <TeamLogo />
        <img id="hometurf-logo" src={HomeTurfLogo} alt="Hometurf" />
        <h5>PRODUCER</h5>
      </div>
      <ul className="nav-list">
        <SelectCategoryLink
          navTitle="Game Media"
          categoryId=""
          onClick={() => {
            dispatch(selectCategory(''));
            dispatch(playMedia(null));
          }}
        />
        {sortedCategories.map((c, index) => (
          <SelectCategoryLink
            key={index}
            navTitle={c.name}
            categoryId={c.id}
            onClick={() => {
              dispatch(selectCategory(c.id));
              dispatch(playMedia(null));
            }}
          />
        ))}
      </ul>
      <div className="exit-container">
        <a className="exit-button" href={`${window.location.origin}/admin`}>
          <CloseIcon />
          Exit
        </a>
      </div>
    </div>
  );
};

export default NavigationSidebar;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityAdapter, AsyncStatus } from './_util';
import { post } from '../services/cms_api_client';

/**
 * Creates a new game poll.
 *
 * {prompt: String, options: [{answer: String}]}  poll - the poll to create
 */
export const createGamePoll = createAsyncThunk(
  'poll/create',
  ({ prompt, options }, { getState }) => {
    const {
      session: { gameInstanceId, teamId },
    } = getState();
    return post(`/admin/game_poll/new.js`, {
      game_poll: {
        prompt,
        game_instance_id: gameInstanceId,
        team_id: teamId,
        question_options_attributes: options.map(({ answer, correct }) => ({
          answer,
          correct,
        })),
      },
    });
  }
);

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'polls',
  initialState: {
    ...adapter.getInitialState(),
    createGamePollStatus: AsyncStatus.Fulfilled,
  },
  reducers: {
    addPolls: adapter.addMany,
    replacePolls: adapter.replaceMany,
    removePolls: adapter.removeMany,
  },
  extraReducers: {
    [createGamePoll.pending]: state => {
      state.createGamePollStatus = AsyncStatus.Pending;
    },
    [createGamePoll.fulfilled]: state => {
      state.createGamePollStatus = AsyncStatus.Fulfilled;
    },
    [createGamePoll.rejected]: state => {
      state.createGamePollStatus = AsyncStatus.Rejected;
    },
  },
});

export const { addPolls, replacePolls, removePolls } = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityAdapter, AsyncStatus } from './_util';
import { post } from '../services/cms_api_client';

/**
 * Creates a new game trivia question.
 *
 * Between 2 and 4 options are allowed, and exactly one must be correct.
 *
 * {prompt: String, options: [{answer: String, correct: boolean}]}  trivia - the trivia to create
 */
export const createGameTrivia = createAsyncThunk(
  'trivia/create',
  ({ prompt, options }, { getState }) => {
    const {
      session: { gameInstanceId, teamId },
    } = getState();
    return post(`/admin/game_trivia/new.js`, {
      game_trivia: {
        prompt,
        game_instance_id: gameInstanceId,
        team_id: teamId,
        question_options_attributes: options.map(({ answer, correct }) => ({
          answer,
          correct,
        })),
      },
    });
  }
);

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'trivia',
  initialState: {
    ...adapter.getInitialState(),
    createGameTriviaStatus: AsyncStatus.Fulfilled,
  },
  reducers: {
    addTrivia: adapter.addMany,
    replaceTrivia: adapter.replaceMany,
    removeTrivia: adapter.removeMany,
  },
  extraReducers: {
    [createGameTrivia.pending]: state => {
      state.createGameTriviaStatus = AsyncStatus.Pending;
    },
    [createGameTrivia.fulfilled]: state => {
      state.createGameTriviaStatus = AsyncStatus.Fulfilled;
    },
    [createGameTrivia.rejected]: state => {
      state.createGameTriviaStatus = AsyncStatus.Rejected;
    },
  },
});

export const { addTrivia, replaceTrivia, removeTrivia } = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { post } from '../services/cms_api_client';
import { EntityAdapter, AsyncStatus } from './_util';
import { useSelector } from 'react-redux';

export const createMedia = createAsyncThunk(
  'media/create',
  ({ asset, title, over21, repeat, gameInstanceId, categoryIds }) =>
    post(`/admin/media/new.js`, {
      media: {
        asset,
        title,
        over21,
        repeat,
        mediable_type: 'GameInstance',
        mediable_id: gameInstanceId,
        category_ids: categoryIds,
        source: 'GAME_DASHBOARD',
      },
    })
);

export const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'media',
  initialState: {
    ...adapter.getInitialState(),
    createMediaStatus: AsyncStatus.Fulfilled,
    currentlyPlayingMediaId: '',
  },
  reducers: {
    addMedia: adapter.addMany,
    replaceMedia: adapter.replaceMany,
    removeMedia: adapter.removeMany,
    playMedia: (state, { payload: mediaId }) => {
      state.currentlyPlayingMediaId = mediaId;
    },
  },
  extraReducers: {
    [createMedia.pending]: state => {
      state.createMediaStatus = AsyncStatus.Pending;
    },
    [createMedia.fulfilled]: state => {
      state.createMediaStatus = AsyncStatus.Fulfilled;
    },
    [createMedia.rejected]: state => {
      state.createMediaStatus = AsyncStatus.Rejected;
    },
  },
});

export const { addMedia, replaceMedia, removeMedia, playMedia } = slice.actions;

export const useCurrentlyPlayingMediaId = () =>
  useSelector(
    ({ media: { currentlyPlayingMediaId } }) => currentlyPlayingMediaId
  );

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;

import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import {
  addCategories,
  replaceCategories,
  removeCategories,
} from '../ducks/categories';

export const useCategoryChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('CategoryChannel', {
    add: ({ categories }) => dispatch(addCategories(categories)),
    update: ({ categories }) => dispatch(replaceCategories(categories)),
    remove: ({ categories }) => dispatch(removeCategories(categories)),
  });
};

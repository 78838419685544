import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createGamePoll } from '../ducks/polls';
import '../stylesheets/CreatePollModal.scss';
import PollOptionRow from './PollOptionRow';
import { unwrapResult } from '@reduxjs/toolkit';

const CreatePollModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState('');
  const [pollOptionsList, setPollOptionsList] = useState([
    { answer: 'Option' },
    { answer: 'Option' },
  ]);

  const submit = () => {
    if (validateForm()) {
      dispatch(
        createGamePoll({
          prompt: prompt,
          options: pollOptionsList,
        })
      )
        .then(unwrapResult)
        .then(() => {
          handleClose();
          resetForm();
        })
        .catch(() => showErrorMessage());
    } else {
      alert('Please fill out all details for the form.');
    }
  };

  const validateForm = () => {
    if (prompt !== '') {
      for (var i = 0; i < pollOptionsList.length; i++) {
        if (pollOptionsList[i].answer === 'Option') {
          return false;
        }
      }
    }
    return true;
  };

  const resetForm = () => {
    setPrompt('');
    setPollOptionsList([{ answer: 'Option' }, { answer: 'Option' }]);
  };

  const showErrorMessage = () => {
    alert('Poll creation failed... Please try again!');
  };

  const addOptionInput = () => {
    if (pollOptionsList.length < 4) {
      setPollOptionsList([...pollOptionsList, { answer: 'Option' }]);
    }
  };

  const removeOption = index => {
    if (pollOptionsList.length > 2) {
      const list = [...pollOptionsList];
      list.splice(index, 1);
      setPollOptionsList(list);
    }
  };

  const handleTextChange = (newText, index) => {
    const list = [...pollOptionsList];
    list[index]['answer'] = newText;
    setPollOptionsList(list);
  };

  return (
    <Modal id="poll" show={show} size="xl" centered onHide={() => {}}>
      <div className="modal-media-details-form">
        <h3>Create Poll</h3>
        <input
          type="text"
          maxLength="100"
          name="title"
          className="media-text-field"
          placeholder="Question"
          value={prompt}
          onChange={() => setPrompt(event.target.value)}
        />
        <div className="options-list">
          {pollOptionsList.map((option, index) => {
            return (
              <PollOptionRow
                placeholder={option.answer}
                key={index}
                i={index}
                onRemove={() => removeOption(index)}
                handleTextChange={option => handleTextChange(option, index)}
              />
            );
          })}
          {pollOptionsList.length < 4 && (
            <Button className="add-option-button" onClick={addOptionInput}>
              Add Option
            </Button>
          )}
        </div>
        <Button className="create-trivia" onClick={submit}>
          Create Poll
        </Button>
        <Button
          className="cancel-trivia"
          onClick={() => {
            handleClose();
            resetForm();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default CreatePollModal;

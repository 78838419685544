import React, { useState } from 'react';
import '../stylesheets/TriviaListRow.scss';
import ConfirmModal from './ConfirmModal';
import { useTeamConfiguration } from '../ducks/teamConfiguration';
import SendButton from './SendButton';

const TriviaListRow = ({ trivia, onSend, showDetails }) => {
  const [confirmationPresented, setConfirmationPresented] = useState(false);

  const presentConfirmation = () => setConfirmationPresented(true);
  const dismissConfirmation = () => setConfirmationPresented(false);

  const teamConfig = useTeamConfiguration();

  const teamRequiresConfirmation = () => {
    return teamConfig.confirm_before_sending;
  };

  const lastInteractionAt = () => {
    const lastInteraction = trivia.last_interaction_at;
    const tempDate = new Date(lastInteraction);
    return tempDate.toLocaleDateString();
  };

  return (
    <div className="trivia-container">
      <ConfirmModal
        title="Confirm Interaction"
        confirmButtonTitle="Trigger Interaction"
        onConfirm={() => {
          dismissConfirmation();
          onSend();
        }}
        show={confirmationPresented}
        handleClose={dismissConfirmation}
      />
      {showDetails && trivia.last_interaction_at ? (
        <div className="previously-dispatched">
          <div className="details-text-box">
            <h3>{trivia.prompt}</h3>
            <p>Last used: {lastInteractionAt()}</p>
          </div>
        </div>
      ) : (
        <h3>{trivia.prompt}</h3>
      )}
      <div className="input-container">
        <div className="send-button-container">
          <SendButton
            onSend={() => {
              teamRequiresConfirmation() ? presentConfirmation() : onSend();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TriviaListRow;

import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import '../stylesheets/GameMediaCard.scss';
import ConfirmModal from './ConfirmModal';
import { useTeamConfiguration } from '../ducks/teamConfiguration';
import { useCurrentlyPlayingMediaId } from '../ducks/media';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SendButton from './SendButton';
import { useTriggerInteractionStatus } from '../ducks/interactions';
import { AsyncStatus } from '../ducks';

const GameMediaCard = ({
  title,
  asset,
  assetType,
  thumbnail,
  repeat,
  onSend,
  mediaId,
  onPlayClicked,
  lightModeEnabled,
}) => {
  const [confirmationPresented, setConfirmationPresented] = useState(false);
  const [repeatChecked, setRepeatChecked] = useState(
    lightModeEnabled ? false : repeat
  );

  const presentConfirmation = () => setConfirmationPresented(true);
  const dismissConfirmation = () => setConfirmationPresented(false);

  const teamConfig = useTeamConfiguration();
  const currentlyPlayingMediaId = useCurrentlyPlayingMediaId();
  const triggerInteractionStatus = useTriggerInteractionStatus();

  const isPlaying = mediaId === currentlyPlayingMediaId;
  const isVideo = assetType.includes('video');

  const teamRequiresConfirmation = () => {
    return teamConfig.confirm_before_sending;
  };

  const onSendClicked = () => {
    if (triggerInteractionStatus !== AsyncStatus.Pending) {
      teamRequiresConfirmation()
        ? presentConfirmation()
        : onSend(repeatChecked);
    }
  };

  return (
    <Paper className="media-card" elevation={5}>
      <ConfirmModal
        title="Confirm Interaction"
        confirmButtonTitle="Trigger Interaction"
        onConfirm={() => {
          dismissConfirmation();
          onSend(repeatChecked);
        }}
        show={confirmationPresented}
        handleClose={dismissConfirmation}
      />
      <div className="media">
        {isVideo && isPlaying ? (
          <video controls autoPlay>
            <source src={asset} type="video/mp4"></source>
          </video>
        ) : (
          <div className="preview-container">
            <div className="center">
              <img src={thumbnail} alt="temporary" />
            </div>
            <div
              className={`center ${isPlaying || !isVideo ? 'hide' : ''}`}
              onClick={onPlayClicked}
            >
              <PlayArrowIcon className="play-button" />
            </div>
          </div>
        )}
      </div>
      <div className="card-title-text">
        <p>{title}</p>
      </div>
      <div className="card-interactions">
        {!lightModeEnabled && (
          <div className="card-checkbox">
            <input
              name="repeat"
              type="checkbox"
              checked={!lightModeEnabled && repeatChecked}
              disabled={lightModeEnabled}
              onChange={event => setRepeatChecked(event.target.checked)}
            />
            <p>Repeat</p>
          </div>
        )}
        <div className="send-media-button-container">
          <SendButton onSend={onSendClicked} />
        </div>
      </div>
    </Paper>
  );
};

export default GameMediaCard;

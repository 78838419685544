import React, { useState } from 'react';
import '../stylesheets/StatsListRow.scss';
import ConfirmModal from './ConfirmModal';
import { useTeamConfiguration } from '../ducks/teamConfiguration';
import DropdownMenu from './DropdownMenu';
import { useDispatch } from 'react-redux';
import { triggerInteraction } from '../ducks/interactions';
import { useSessionState } from '../ducks/session';
import SendButton from './SendButton';
import { buildDropdownOptions } from '../services/statistics';

const StatsListRow = ({ stat }) => {
  const title = stat.statistic.name;
  const options = buildDropdownOptions(stat);

  const [confirmationPresented, setConfirmationPresented] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const presentConfirmation = () => setConfirmationPresented(true);
  const dismissConfirmation = () => setConfirmationPresented(false);

  const [counter, setCounter] = useState(0);
  const incrementCounter = () => setCounter(prev => prev + 1);

  const teamConfig = useTeamConfiguration();
  const dispatch = useDispatch();
  const { gameInstanceId } = useSessionState();

  const teamRequiresConfirmation = () => {
    return teamConfig.confirm_before_sending;
  };

  const triggerStatsInteraction = () => {
    const options = selectedOptions.reduce((obj, option) => {
      obj[option.key] = option.id;
      return obj;
    }, {});

    dispatch(
      triggerInteraction({
        gameInstanceId,
        interactionInfo: {
          type: 'stats',
          title: stat.statistic.name,
          statKey: stat.statistic.remote_key,
          options,
          statQueryMeta: stat.sr_metadata,
        },
      })
    ).then(({ error }) => {
      if (!error) {
        setSelectedOptions([]);
        incrementCounter([]);
      }
    });
  };

  const selectionsMade = () =>
    selectedOptions.filter(Boolean).length === options.length;

  const optionSelected = optionId => {
    return selectedOptions.some(e => e.key === optionId);
  };

  const onSelectOption = ([value], index) => {
    if (value && !optionSelected(value.id)) {
      const newArr = [...selectedOptions];
      newArr[index] = value;
      setSelectedOptions(newArr);
    }
  };

  return (
    <div className="text-entry">
      <ConfirmModal
        title="Confirm Interaction"
        confirmButtonTitle="Trigger Interaction"
        onConfirm={() => {
          dismissConfirmation();
          triggerStatsInteraction();
        }}
        show={confirmationPresented}
        handleClose={dismissConfirmation}
      />
      <h3>{title}</h3>
      {options.map((category, index) => {
        return (
          <div key={`${category.name}${counter}`} className="drop-down-box">
            <DropdownMenu
              selectionMade={selectedOptions[index]}
              dropdownText={category.name}
              options={category.options}
              onChange={values => onSelectOption(values, index)}
            />
          </div>
        );
      })}
      <div className="input-container">
        <div
          className={`send-button-container ${
            !selectionsMade() ? 'disabled' : ''
          }`}
        >
          <SendButton
            disabled={!selectionsMade()}
            onSend={() => {
              teamRequiresConfirmation()
                ? presentConfirmation()
                : triggerStatsInteraction();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StatsListRow;

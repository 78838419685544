import React, { useState } from 'react';
import '../stylesheets/SoundEffectButton';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { triggerInteraction } from '../ducks/interactions';
import { useSessionState } from '../ducks/session';
import { useDispatch } from 'react-redux';
import SendButton from './SendButton';

const SoundEffectButton = ({
  sound: { title, repeat, id, asset_type: assetType },
  onRepeatingSound,
}) => {
  const [repeatChecked, setRepeatChecked] = useState(repeat);
  const dispatch = useDispatch();
  const { gameInstanceId } = useSessionState();
  return (
    <div className="sound-effect-button">
      <div className="sounds-row">
        <div className="sound-effect-icon">{<VolumeUpIcon />}</div>
        <div className="sound-effect-properties">
          <div className="sound-effect-stack">
            <p id="text">{title}</p>
            <div className="button-checkbox">
              <input
                name="repeat"
                type="checkbox"
                checked={repeatChecked}
                onChange={event => setRepeatChecked(event.target.checked)}
              />
              <p>Repeat</p>
            </div>
          </div>
        </div>
        <div className="send-button-container">
          <SendButton
            onSend={() => {
              dispatch(
                triggerInteraction({
                  gameInstanceId,
                  interactionInfo: {
                    type: 'media',
                    title: title,
                    mediaId: id,
                    mediaContentType: assetType,
                    isRepeating: repeatChecked,
                  },
                })
              ).then(({ error }) => {
                if (!error) {
                  onRepeatingSound(repeatChecked);
                }
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SoundEffectButton;

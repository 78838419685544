import { post } from '../services/cms_api_client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus } from './_util';
import { useSelector } from 'react-redux';

export const triggerInteraction = createAsyncThunk(
  'interactions/trigger',
  ({ gameInstanceId, interactionInfo }, { dispatch, requestId }) => {
    return post(
      `/api/interaction/trigger`,
      createInteractionRequest(gameInstanceId, interactionInfo)
    ).catch(error => {
      dispatch(
        setInteractionDetails({
          error: error.message || 'error triggering interaction',
          snackbarPreview: interactionInfo.snackbarPreview,
          id: requestId,
        })
      );
      throw error;
    });
  },
  {
    condition: (_, { getState }) =>
      getState().interactions.triggerInteractionStatus !== AsyncStatus.Pending,
  }
);

const slice = createSlice({
  name: 'interactions',
  initialState: {
    triggerInteractionStatus: AsyncStatus.Fulfilled,
    interactionDetails: {},
  },
  reducers: {
    setInteractionDetails: (
      state,
      { payload: { error, title, id, snackbarPreview, interactionType } }
    ) => {
      state.interactionDetails = {
        error,
        title,
        id,
        snackbarPreview,
        interactionType,
      };
    },
  },
  extraReducers: {
    [triggerInteraction.pending]: state => {
      state.triggerInteractionStatus = AsyncStatus.Pending;
    },
    [triggerInteraction.fulfilled]: state => {
      state.triggerInteractionStatus = AsyncStatus.Fulfilled;
    },
    [triggerInteraction.rejected]: state => {
      state.triggerInteractionStatus = AsyncStatus.Rejected;
    },
  },
});

const { setInteractionDetails } = slice.actions;

export const useInteractions = () =>
  useSelector(({ interactions: { interactionDetails } }) => interactionDetails);

export const useTriggerInteractionStatus = () =>
  useSelector(
    ({ interactions: { triggerInteractionStatus } }) => triggerInteractionStatus
  );

export default slice.reducer;

export const interactionSent = ({
  id,
  interaction,
  metadata = {},
}) => dispatch => {
  dispatch(
    setInteractionDetails({ id, interactionType: interaction, ...metadata })
  );
};

const createInteractionRequest = (gameInstanceId, interactionInfo) => {
  const interactionType = interactionInfo.type;
  const request = {
    gameInstanceId,
    metadata: {},
    type: interactionType,
  };

  // add mediaId if this is a media
  if (interactionType === 'media') {
    const {
      mediaId,
      mediaContentType,
      over21,
      isRepeating,
      snackbarPreview,
    } = interactionInfo;

    request.metadata.mediaId = mediaId;
    request.metadata.mediaContentType = mediaContentType;
    request.metadata.over21 = over21;
    request.metadata.repeat = isRepeating;
    request.metadata.snackbarPreview = snackbarPreview;
  }

  // add trivia metadata if this is a trivia interaction
  if (interactionType === 'trivia') {
    const { triviaId, question, options } = interactionInfo;

    request.metadata.triviaId = triviaId;
    request.metadata.question = question;

    // the JSON blob for options has one property 'options'
    // that is an array of options... so just removing a layer here
    // when sending to the main API
    request.metadata.options = options;
  }

  // add poll metadata if this is a poll interaction
  if (interactionType === 'poll') {
    const { pollId, question, options } = interactionInfo;

    request.metadata.pollId = pollId;
    request.metadata.question = question;

    // the JSON blob for options has one property 'options'
    // that is an array of options... so just removing a layer here
    // when sending to the main API
    request.metadata.options = options;
  }

  // add stat metadata if this is a stat interaction
  if (interactionType === 'stats') {
    const { statKey, options, statQueryMeta } = interactionInfo;

    request.metadata = { statKey, statQueryMeta, options };
  }

  if (
    ['webview', 'hls-stream-start', 'season-ticket-stream-start'].includes(
      interactionType
    )
  ) {
    const { url } = interactionInfo;
    request.metadata = { url };
  }

  request.metadata.title = interactionInfo.title;

  return request;
};

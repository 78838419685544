import React from 'react';
import '../stylesheets/SearchBar.scss';
import SearchIcon from '@material-ui/icons/Search';

const SearchBar = ({ searchQuery, onSearchQueryChanged }) => {
  return (
    <div className="input-group">
      <input
        className="search-bar"
        placeholder="Search for"
        value={searchQuery}
        onChange={onSearchQueryChanged}
      />
      <SearchIcon className="search-icon" />
    </div>
  );
};

export default SearchBar;

import React from 'react';
import '../stylesheets/TeamLogo';
import { useTeamConfiguration } from '../ducks/teamConfiguration';

const TeamLogo = () => {
  const teamConfig = useTeamConfiguration();
  const teamLogoUrl = teamConfig
    ? (teamConfig.team_logo || {}).asset_url
    : null;
  return <img className="team-logo" src={teamLogoUrl} />;
};

export default TeamLogo;

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const slice = createSlice({
  name: 'teamConfiguration',
  initialState: { configuration: null },
  reducers: {
    addTeamConfiguration: (state, { payload: configuration = null }) => {
      state.configuration = configuration;
    },
    setTeamConfiguration: (state, { payload: configuration = null }) => {
      state.configuration = configuration;
    },
    removeTeamConfiguration: state => {
      state.configuration = null;
    },
  },
});

export const {
  addTeamConfiguration,
  setTeamConfiguration,
  removeTeamConfiguration,
} = slice.actions;

export const useTeamConfiguration = () =>
  useSelector(({ teamConfiguration: { configuration } }) => configuration);

export default slice.reducer;

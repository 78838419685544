import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useState, useMemo } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { AsyncStatus } from '../ducks';
import {
  selectors as categorySelectors,
  useSelectedCategory,
} from '../ducks/categories';
import {
  triggerInteraction,
  useTriggerInteractionStatus,
} from '../ducks/interactions';
import { useSessionState } from '../ducks/session';
import { playMedia, selectors as mediaSelectors } from '../ducks/media';
import { selectors as pollSelectors } from '../ducks/polls';
import { selectors as statsSelectors } from '../ducks/statistics';
import { useTeamConfiguration } from '../ducks/teamConfiguration';
import { selectors as triviaSelectors } from '../ducks/trivia';
import '../stylesheets/GameMediaContainer.scss';
import AddGameMediaModal from './AddGameMediaModal';
import CreatePollModal from './CreatePollModal';
import CreateTriviaModal from './CreateTriviaModal';
import FilterDropdown from './FilterDropdown';
import GameMediaCard from './GameMediaCard';
import PollListRow from './PollListRow';
import StatsListRow from './StatsListRow';
import ToastAlert from './ToastAlert';
import TriviaListRow from './TriviaListRow';
import { isNil } from 'lodash';
import iPhoneDevice from '../../assets/images/iphone.png';
import AndroidDevice from '../../assets/images/android.png';
import { post } from '../services/cms_api_client';
import ChatModerationTool from './ChatModerationTool';
import { StreamChat } from 'stream-chat';
import SearchIcon from '@material-ui/icons/Search';

const GameMediaContainer = ({ gameInstanceId, lightModeEnabled }) => {
  const dispatch = useDispatch();
  const stats = useSelector(statsSelectors.selectAll);
  const media = useSelector(mediaSelectors.selectAll);
  const trivia = useSelector(triviaSelectors.selectAll);
  const polls = useSelector(pollSelectors.selectAll);
  const teamConfig = useTeamConfiguration();
  const categories = useSelector(categorySelectors.selectAll);
  const selectedCategory = useSelectedCategory();
  const triggerInteractionStatus = useTriggerInteractionStatus();
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterKeyId, setFilterKeyId] = useState(0);
  const noFilter = { type: 'all', cutoffDays: 0 };
  const [{ type: filterType, cutoffDays }, setFilter] = useState(noFilter);
  const clearFilter = () => setFilter(noFilter);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [isSendingPush, setIsSendingPush] = useState(false);

  const { teamName, config } = useSessionState();
  const { getstreamKey, getstreamToken, adminUserEmail } = config;

  const resetFilterSelect = () => {
    setFilterKeyId(current => current + 1);
    clearFilter();
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const chatClient = useMemo(() => {
    const chatClient = new StreamChat(getstreamKey);
    chatClient.setUser(
      {
        id: adminUserEmail,
        name: `${teamName} Admin`,
      },
      getstreamToken
    );
    return chatClient;
  }, [getstreamKey, getstreamToken, adminUserEmail]);

  const sendableMedia = media
    .filter(m => m.processing_state === 'succeeded' && m.last_published_at)
    .sort((a, b) => {
      if (a.title.toLowerCase() === b.title.toLowerCase()) return 0;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    });

  const numMediaCurrentlyPocessing = media.filter(
    m => m.processing_state !== 'succeeded' && m.source === 'GAME_DASHBOARD'
  ).length;

  const filteredMediaForCurrentCategory = sendableMedia.filter(
    m =>
      !selectedCategory ||
      m.categories.some(c => c.id.includes(selectedCategory))
  );

  const dynamicSearch = mediaType => {
    switch (mediaType) {
      case 'media':
        return filteredMediaForCurrentCategory.filter(media =>
          media.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      case 'trivia':
        return filteredTriviaBeforeCutoffDate.filter(trivia =>
          trivia.prompt.toLowerCase().includes(searchQuery.toLowerCase())
        );
      case 'polls':
        return filteredPollsBeforeCutoffDate.filter(poll =>
          poll.prompt.toLowerCase().includes(searchQuery.toLowerCase())
        );
      case 'used-trivia':
        return filteredTriviaAfterCutoffDate.filter(trivia =>
          trivia.prompt.toLowerCase().includes(searchQuery.toLowerCase())
        );
      case 'used-polls':
        return filteredPollsAfterCutoffDate.filter(poll =>
          poll.prompt.toLowerCase().includes(searchQuery.toLowerCase())
        );
      case 'stats':
        return stats.filter(stat =>
          stat.statistic.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }
  };

  const filterQuestions = allQuestions => {
    const questions = (() => {
      switch (filterType) {
        case 'all':
          return allQuestions;
        case 'global':
          return allQuestions.filter(({ team_id }) => isNil(team_id));
        case 'team':
          return allQuestions.filter(({ team_id }) => !isNil(team_id));
      }
    })();

    const today = new Date();
    const cutoffDate = new Date();
    cutoffDate.setDate(today.getDate() - cutoffDays);

    const cutoffSet = cutoffDays !== null && cutoffDays !== 0;

    return !cutoffSet
      ? { before: questions, after: questions }
      : {
          before: questions.filter(
            question =>
              question.last_interaction_at < cutoffDate.toISOString() ||
              question.last_interaction_at === null
          ),
          after: questions.filter(
            question => question.last_interaction_at > cutoffDate.toISOString()
          ),
        };
  };

  const {
    before: filteredTriviaBeforeCutoffDate,
    after: filteredTriviaAfterCutoffDate,
  } = filterQuestions(trivia);

  const {
    before: filteredPollsBeforeCutoffDate,
    after: filteredPollsAfterCutoffDate,
  } = filterQuestions(polls);

  const sortByLastInteraction = (a, b) => {
    if (a.last_interaction_at === null) {
      return -1;
    } else {
      return a.last_interaction_at - b.last_interaction_at;
    }
  };

  const comesBeforeCutoff = item => {
    const today = new Date();
    const cutoff = new Date();
    cutoff.setDate(today.getDate() - cutoffDays);
    return item.last_interaction_at < cutoff.toISOString();
  };

  const clearForm = () => {
    setNotificationTitle('');
    setNotificationBody('');
  };

  const hasFeed = (feeds, feedName) => {
    return feeds.some(feed => feed.feed_name === feedName);
  };

  const getFeedUrl = (feeds, feedName) => {
    const feed = feeds.find(feed => feed.feed_name === feedName);
    return feed.feed_url;
  };

  const hasSocialUrl = () => teamConfig && teamConfig.social_wall_url;
  const hasDefaultHLSUrl = () =>
    teamConfig &&
    teamConfig.team_configuration_feeds &&
    hasFeed(teamConfig.team_configuration_feeds, 'DEFAULT');
  const hasSeasonTicketUrl = () =>
    teamConfig &&
    teamConfig.team_configuration_feeds &&
    hasFeed(teamConfig.team_configuration_feeds, 'LEGACY SEASON TICKET');

  const hasHlsUrl = () =>
    teamConfig &&
    teamConfig.team_configuration_feeds &&
    hasFeed(teamConfig.team_configuration_feeds, 'LEGACY STREAM');

  const ableToTriggerStreams = () => {
    return hasHlsUrl() || hasSeasonTicketUrl();
  };

  const sendPushNotification = () => {
    if (notificationTitle.length > 0 && notificationBody.length > 0) {
      setIsSendingPush(true);
      post(`/api/notification/${teamConfig.team_id}`, {
        title: notificationTitle,
        body: notificationBody,
      })
        .then(() => {
          alert('Notification sent!');
          setIsSendingPush(false);
          clearForm();
        })
        .catch(error => {
          alert('Error sending notification: ', error);
          setIsSendingPush(false);
        });
    } else {
      alert('Please fill out a title and body for your push notification.');
    }
  };

  return (
    <div className="main-container col">
      <ToastAlert />
      <div className="main-container middle">
        <div className="top-row">
          <div className="media-tabs">
            <NavLink
              to="/media"
              className="inactive-media-tab"
              activeClassName="active-media-tab"
              onClick={() => setSearchQuery('')}
            >
              Game Media
            </NavLink>
            <NavLink
              to="/trivia"
              className="inactive-media-tab"
              activeClassName="active-media-tab"
              onClick={() => {
                resetFilterSelect();
                setSearchQuery('');
              }}
            >
              Trivia
            </NavLink>
            <NavLink
              to="/polls"
              className="inactive-media-tab"
              activeClassName="active-media-tab"
              onClick={() => {
                resetFilterSelect();
                setSearchQuery('');
              }}
            >
              Polls
            </NavLink>
            {/*<NavLink*/}
            {/*  to="/stats"*/}
            {/*  className="inactive-media-tab"*/}
            {/*  activeClassName="active-media-tab"*/}
            {/*  onClick={() => setSearchQuery('')}*/}
            {/*>*/}
            {/*  Stats*/}
            {/*</NavLink>*/}
            <NavLink
              to="/moderation"
              className="inactive-media-tab"
              activeClassName="active-media-tab"
              onClick={() => setSearchQuery('')}
            >
              Chat Moderation
            </NavLink>
          </div>
          {!lightModeEnabled && (
            <div className="shortcuts-container">
              <div className="btn-group flex-wrap" role="group">
                <Spinner
                  hidden={triggerInteractionStatus !== AsyncStatus.Pending}
                  animation="border"
                  className="loading-spinner"
                />
                <button
                  className="shortcut-item "
                  disabled={hasDefaultHLSUrl() || true}
                  onClick={() => {
                    dispatch(
                      triggerInteraction({
                        gameInstanceId,
                        interactionInfo: {
                          title: 'Heartbeat',
                          type: 'live-stream-stop',
                        },
                      })
                    );
                  }}
                >
                  <p>Heartbeat</p>
                </button>
                <button
                  disabled={!hasSocialUrl()}
                  className={`shortcut-item ${
                    hasSocialUrl() ? '' : 'disabled'
                  }`}
                  onClick={() =>
                    dispatch(
                      triggerInteraction({
                        gameInstanceId,
                        interactionInfo: {
                          title: 'Social Wall',
                          type: 'webview',
                          url: teamConfig.social_wall_url,
                        },
                      })
                    )
                  }
                >
                  <p>Social</p>
                </button>
                <div className="btn-group shortcuts" role="group">
                  <button
                    id="stream-dropdown"
                    disabled={!ableToTriggerStreams()}
                    className={`btn dropdown-toggle shortcut-dropdown ${
                      ableToTriggerStreams ? '' : 'disabled'
                    }`}
                    data-toggle="dropdown"
                  >
                    <p>Stream</p>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <button
                      disabled={!hasHlsUrl()}
                      className={`dropdown-item ${
                        hasHlsUrl() ? '' : 'disabled'
                      }`}
                      onClick={() =>
                        dispatch(
                          triggerInteraction({
                            gameInstanceId,
                            interactionInfo: {
                              title: 'HLS Livestream',
                              type: 'hls-stream-start',
                              url: getFeedUrl(
                                teamConfig.team_configuration_feeds,
                                'LEGACY STREAM'
                              ),
                            },
                          })
                        )
                      }
                    >
                      <p>ALL ON</p>
                    </button>
                    <button
                      disabled={!hasSeasonTicketUrl()}
                      className={`dropdown-item ${
                        hasSeasonTicketUrl() ? '' : 'disabled'
                      }`}
                      onClick={() =>
                        dispatch(
                          triggerInteraction({
                            gameInstanceId,
                            interactionInfo: {
                              title: 'Start HLS Season Ticket Livestream',
                              type: 'season-ticket-stream-start',
                              url: getFeedUrl(
                                teamConfig.team_configuration_feeds,
                                'LEGACY SEASON TICKET'
                              ),
                            },
                          })
                        )
                      }
                    >
                      <p>STM ON</p>
                    </button>
                    <button
                      disabled={!hasSeasonTicketUrl()}
                      className={`dropdown-item ${
                        hasSeasonTicketUrl() ? '' : 'disabled'
                      }`}
                      onClick={() =>
                        dispatch(
                          triggerInteraction({
                            gameInstanceId,
                            interactionInfo: {
                              title: 'Stop HLS Season Ticket Livestream',
                              type: 'season-ticket-stream-stop',
                            },
                          })
                        )
                      }
                    >
                      <p>STM OFF</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="ht-media-grid">
          <Switch>
            <Route path={'/media'}>
              <div className="media-tools">
                <div className="input-group">
                  <input
                    className="search-bar"
                    placeholder="Search for"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  <SearchIcon className="search-icon" />
                </div>
              </div>
              <h3>
                {numMediaCurrentlyPocessing > 0
                  ? `${numMediaCurrentlyPocessing} media processing`
                  : ''}
              </h3>
              <div className="media-row">
                <AddGameMediaModal
                  show={show}
                  categories={categories.filter(c => !c.global)}
                  handleClose={() => {
                    handleClose();
                  }}
                />
                {media.length === 0 ? (
                  <div className="spinner-container">
                    <Spinner animation="border" className="loading-spinner" />
                  </div>
                ) : (
                  dynamicSearch('media').map(item => (
                    <GameMediaCard
                      repeat={item.repeat}
                      key={item.id}
                      mediaId={item.id}
                      title={item.title}
                      onSend={repeatChecked =>
                        dispatch(
                          triggerInteraction({
                            gameInstanceId,
                            interactionInfo: {
                              mediaId: item.id,
                              title: item.title,
                              mediaContentType: item.asset_type,

                              snackbarPreview: item.thumbnail_url,
                              type: 'media',
                              mediaType: 'video',
                              isRepeating: repeatChecked,
                              over21: item.over21,
                            },
                          })
                        )
                      }
                      thumbnail={item.thumbnail_url}
                      asset={item.asset_url}
                      assetType={item.asset_type}
                      onPlayClicked={() => dispatch(playMedia(item.id))}
                      lightModeEnabled={lightModeEnabled}
                    />
                  ))
                )}
              </div>
            </Route>
            <Route path="/trivia">
              <div className="media-tools">
                <div className="input-group">
                  <input
                    className="search-bar"
                    placeholder="Search for"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  <SearchIcon className="search-icon" />
                </div>
                <FilterDropdown
                  key={filterKeyId}
                  onFilterChange={setFilter}
                  entityLabel="Trivia"
                />
                <button className="add-media-button" onClick={handleShow}>
                  <AddCircleIcon />
                  Add Game Trivia
                </button>
              </div>
              <div className="text-media-container">
                <CreateTriviaModal
                  show={show}
                  handleClose={() => {
                    handleClose();
                  }}
                />
                <div className="bottom-margin">
                  {dynamicSearch('trivia')
                    .sort(sortByLastInteraction)
                    .map((item, index) => (
                      <TriviaListRow
                        showDetails={
                          cutoffDays === 0 || !comesBeforeCutoff(item)
                        }
                        key={index}
                        trivia={item}
                        onSend={() => {
                          dispatch(
                            triggerInteraction({
                              gameInstanceId,
                              interactionInfo: {
                                type: 'trivia',
                                title: item.prompt,
                                triviaId: item.id,
                                question: item.prompt,
                                options: item.question_options,
                              },
                            })
                          );
                        }}
                      />
                    ))}
                </div>

                {cutoffDays !== 0 && (
                  <div className="bottom-section">
                    {dynamicSearch('used-trivia')
                      .sort(sortByLastInteraction)
                      .map((item, index) => (
                        <TriviaListRow
                          showDetails={true}
                          key={index}
                          trivia={item}
                          onSend={() => {
                            dispatch(
                              triggerInteraction({
                                gameInstanceId,
                                interactionInfo: {
                                  type: 'trivia',
                                  title: item.prompt,
                                  triviaId: item.id,
                                  question: item.prompt,
                                  options: item.question_options,
                                },
                              })
                            );
                          }}
                        />
                      ))}
                  </div>
                )}
              </div>
            </Route>
            <Route path="/polls">
              <div className="media-tools">
                <div className="input-group">
                  <input
                    className="search-bar"
                    placeholder="Search for"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  <SearchIcon className="search-icon" />
                </div>
                <FilterDropdown
                  key={filterKeyId}
                  onFilterChange={setFilter}
                  entityLabel="Polls"
                />
                <button className="add-media-button" onClick={handleShow}>
                  <AddCircleIcon />
                  Add Game Poll
                </button>
              </div>
              <div className="text-media-container">
                <CreatePollModal
                  show={show}
                  handleClose={() => {
                    handleClose();
                  }}
                />
                <div className="bottom-margin">
                  {dynamicSearch('polls')
                    .sort(sortByLastInteraction)
                    .map((item, index) => (
                      <PollListRow
                        key={index}
                        showDetails={
                          cutoffDays === 0 || !comesBeforeCutoff(item)
                        }
                        question={item}
                        onSend={() =>
                          dispatch(
                            triggerInteraction({
                              gameInstanceId,
                              interactionInfo: {
                                type: 'poll',
                                title: item.prompt,
                                pollId: item.id,
                                question: item.prompt,
                                options: item.question_options,
                              },
                            })
                          )
                        }
                      />
                    ))}
                </div>
                {cutoffDays !== 0 && (
                  <div>
                    {dynamicSearch('used-polls')
                      .sort(sortByLastInteraction)
                      .map((item, index) => (
                        <PollListRow
                          showDetails={true}
                          key={index}
                          question={item}
                          onSend={() => {
                            dispatch(
                              triggerInteraction({
                                gameInstanceId,
                                interactionInfo: {
                                  type: 'poll',
                                  title: item.prompt,
                                  triviaId: item.id,
                                  question: item.prompt,
                                  options: item.question_options,
                                },
                              })
                            );
                          }}
                        />
                      ))}
                  </div>
                )}
              </div>
            </Route>
            <Route path="/stats">
              <div className="media-tools">
                <div className="input-group">
                  <input
                    className="search-bar"
                    placeholder="Search for"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  <SearchIcon className="search-icon" />
                </div>
              </div>
              <div className="text-media-container">
                {dynamicSearch('stats').map((item, index) => (
                  <StatsListRow key={index} stat={item} />
                ))}
              </div>
            </Route>
            <Route path="/notifications">
              <div className="notifications-form">
                <div className="notifications-input">
                  <label>Notification Title</label>
                  <input
                    maxLength="60"
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={notificationTitle}
                    onChange={event => setNotificationTitle(event.target.value)}
                  />
                </div>
                <div className="notifications-input">
                  <label>Notification Body</label>
                  <input
                    maxLength="60"
                    type="text"
                    name="title"
                    placeholder="Body"
                    value={notificationBody}
                    onChange={event => setNotificationBody(event.target.value)}
                  />
                </div>
                <button
                  disabled={isSendingPush}
                  className="send-notification-button"
                  onClick={sendPushNotification}
                >
                  Send
                </button>
              </div>
              <div>
                <div className="iphone-preview">
                  <img src={iPhoneDevice} />
                  <div className="notification">
                    <p id="notification-title">{notificationTitle}</p>
                    <p>{notificationBody}</p>
                  </div>
                </div>
                <div className="android-preview">
                  <img src={AndroidDevice} />
                  <div className="notification">
                    <p id="notification-title">{notificationTitle}</p>
                    <p>{notificationBody}</p>
                  </div>
                </div>
              </div>
            </Route>
            <Route path="/moderation">
              <ChatModerationTool
                chatClient={chatClient}
                teamConfig={teamConfig}
              />
            </Route>
            <Route path="/">
              <Redirect to="/media" />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default GameMediaContainer;

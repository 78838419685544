import React from 'react';
import { StoreProvider } from '../ducks';
import '../stylesheets/application';
import Channels from './Channels';
import GameMediaContainer from './GameMediaContainer';
import NavigationSidebar from './NavigationSidebar';
import Router from './Router';
import SoundEffectsSidebar from './SoundEffectsSidebar';
import PusherClient from './PusherClient';
import KeepAPIAlive from "./KeepAPIAlive";

const App = ({
  apiURL,
  gameInstanceId,
  teamName,
  teamId,
  gameId,
  lightModeEnabled,
  config,
}) => {
  return (
    <StoreProvider
      apiURL={apiURL}
      gameInstanceId={gameInstanceId}
      lightModeEnabled={lightModeEnabled}
      teamId={teamId}
      gameId={gameId}
      config={config}
      teamName={teamName}
    >
      <Channels />
      <PusherClient />
      <KeepAPIAlive />
      <Router>
        <div className="app container-fluid">
          <div className="row no-wrap">
            <div className="nav-sidebar col-md-2">
              <NavigationSidebar />
            </div>
            <GameMediaContainer
              gameInstanceId={gameInstanceId}
              lightModeEnabled={lightModeEnabled}
            />
            {!lightModeEnabled && <SoundEffectsSidebar />}
          </div>
        </div>
      </Router>
    </StoreProvider>
  );
};

export default App;

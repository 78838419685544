import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import { addPolls, replacePolls, removePolls } from '../ducks/polls';

export const usePollChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('PollChannel', {
    add: ({ polls }) => dispatch(addPolls(polls)),
    update: ({ polls }) => dispatch(replacePolls(polls)),
    remove: ({ polls }) => dispatch(removePolls(polls)),
  });
};

import { useDispatch } from 'react-redux';
import { addMedia, replaceMedia, removeMedia } from '../ducks/media';
import { useModelChannel } from './model_channel';

export const useMediaChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('MediaChannel', {
    add: ({ media }) => dispatch(addMedia(media)),
    update: ({ media }) => dispatch(replaceMedia(media)),
    remove: ({ media }) => dispatch(removeMedia(media)),
  });
};

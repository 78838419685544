import { createEntityAdapter } from '@reduxjs/toolkit';

class EntityAdapter {
  constructor() {
    this.adapter = createEntityAdapter();
  }

  getInitialState = () => ({ adapter: this.adapter.getInitialState() });

  getSelectors = name =>
    this.adapter.getSelectors(state => state[name].adapter);

  addMany = ({ adapter: state }, action) => {
    this.adapter.addMany(state, action);
  };

  replaceMany = ({ adapter: state }, actionOrEntities) => {
    const entities = actionOrEntities?.payload || actionOrEntities;
    this.adapter.addMany(
      this.adapter.removeMany(
        state,
        entities.map(e => e.id)
      ),
      entities
    );
  };

  removeMany = ({ adapter: state }, actionOrEntities) => {
    const entities = actionOrEntities?.payload || actionOrEntities;
    this.adapter.removeMany(
      state,
      entities.map(e => e.id)
    );
  };
}

export const AsyncStatus = {
  Fulfilled: 'Fullfilled',
  Pending: 'Pending',
  Rejected: 'Rejected',
};

export { EntityAdapter };

import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import {
  addStatistics,
  replaceStatistics,
  removeStatistics,
} from '../ducks/statistics';

export const useStatisticChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('StatisticChannel', {
    add: ({ statistics }) => dispatch(addStatistics(statistics)),
    update: ({ statistics }) => dispatch(replaceStatistics(statistics)),
    remove: ({ statistics }) => dispatch(removeStatistics(statistics)),
  });
};

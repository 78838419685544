import { createSlice } from '@reduxjs/toolkit';
import { EntityAdapter } from './_util';

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'sounds',
  initialState: adapter.getInitialState(),
  reducers: {
    addSounds: adapter.addMany,
    replaceSounds: adapter.replaceMany,
    removeSounds: adapter.removeMany,
  },
});

export const { addSounds, replaceSounds, removeSounds } = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;

import React from 'react';
import '../stylesheets/SendButton.scss';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const SendButton = ({ onSend, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={`send-button ${disabled ? 'disabled' : ''}`}
      onClick={onSend}
    >
      Send <ArrowForwardIosIcon />
    </button>
  );
};

export default SendButton;

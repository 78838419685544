import React from 'react';
import {
  Chat,
  Channel,
  MessageList,
  Window,
  MessageInput,
} from 'stream-chat-react';
import '../stylesheets/ChatModerationTool.scss';
import 'stream-chat-react/dist/css/index.css';
import ChatMessage from './ChatMessage';
import EmptyMessageList from './EmptyMessageList';
import { useGameId } from '../ducks/gameInstance';
import ChatInput from './ChatInput';

const CryptoJS = require('crypto-js');

const ChatModerationTool = ({ chatClient, teamConfig }) => {
  const gameId = useGameId();
  const teamId = teamConfig?.team_id;
  const md5Hash = CryptoJS.MD5(`${gameId}-${teamId}`);
  const hashedString = md5Hash.toString();

  function getChannel() {
    return chatClient.channel('messaging', `feed-${hashedString}`);
  }

  const channel = getChannel();

  const overrideSubmitHandler = async message => {
    message.teamId = teamId;
    message.isFromAdmin = true;
    await channel.sendMessage(message);
  };

  return (
    <React.Fragment>
      <Chat client={chatClient} theme={'messaging light'}>
        <Channel channel={channel} style={{ height: 300 }}>
          <Window>
            <MessageList
              EmptyStateIndicator={EmptyMessageList}
              Message={ChatMessage}
            />
            <MessageInput
              Input={ChatInput}
              overrideSubmitHandler={overrideSubmitHandler}
            />
          </Window>
        </Channel>
      </Chat>
    </React.Fragment>
  );
};

export default ChatModerationTool;

import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../stylesheets/StatisticDropdown.scss';
import DropdownMenu from './DropdownMenu';
import { useDispatch } from 'react-redux';
import { triggerInteraction } from '../ducks/interactions';
import { useSessionState } from '../ducks/session';
import ConfirmModal from './ConfirmModal';
import { useTeamConfiguration } from '../ducks/teamConfiguration';
import { buildDropdownOptions } from '../services/statistics';

const StatisticDropdown = ({ stat }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [confirmationPresented, setConfirmationPresented] = useState(false);

  const [counter, setCounter] = useState(0);
  const incrementCounter = () => setCounter(prev => prev + 1);

  const presentConfirmation = () => setConfirmationPresented(true);
  const dismissConfirmation = () => setConfirmationPresented(false);

  const teamConfig = useTeamConfiguration();

  const teamRequiresConfirmation = () => {
    return teamConfig.confirm_before_sending;
  };

  const dispatch = useDispatch();
  const { gameInstanceId } = useSessionState();

  const options = buildDropdownOptions(stat);

  const selectionsMade = () =>
    selectedOptions.filter(Boolean).length === options.length;

  const optionSelected = optionId => {
    return selectedOptions.some(e => e.key === optionId);
  };

  const onSelectOption = (values, index) => {
    if (!optionSelected(values[0].id)) {
      const newArr = [...selectedOptions];
      newArr[index] = values[0];
      setSelectedOptions(newArr);
    }
  };

  const triggerStatsInteraction = () => {
    const options = selectedOptions.reduce((obj, option) => {
      obj[option.key] = option.id;
      return obj;
    }, {});

    dispatch(
      triggerInteraction({
        gameInstanceId,
        interactionInfo: {
          type: 'stats',
          title: stat.statistic.name,
          statKey: stat.statistic.remote_key,
          options,
          statQueryMeta: stat.sr_metadata,
        },
      })
    ).then(({ error }) => {
      if (!error) {
        setSelectedOptions([]);
        incrementCounter();
      }
    });
  };

  return (
    <Accordion className="stats-dropdown-menu">
      <AccordionSummary
        className="stats-button-title"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {stat.statistic.name}
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <ConfirmModal
          title="Confirm Interaction"
          confirmButtonTitle="Trigger Interaction"
          onConfirm={() => {
            dismissConfirmation();
            triggerStatsInteraction();
          }}
          show={confirmationPresented}
          handleClose={dismissConfirmation}
        />
        <div className="accordion-contents">
          <div className="form-row">
            {options.map((option, index) => (
              <div key={`${option.name}${counter}`} className="dropdown-box">
                <DropdownMenu
                  selectionMade={selectedOptions[index]}
                  dropdownText={option.name}
                  options={option.options}
                  onChange={values => onSelectOption(values, index)}
                />
              </div>
            ))}
          </div>
          <button
            disabled={!selectionsMade()}
            className={`ht-send-stats-button ${
              !selectionsMade() ? 'disabled' : ''
            }`}
            onClick={() => {
              teamRequiresConfirmation()
                ? presentConfirmation()
                : triggerStatsInteraction();
            }}
          >
            Send
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default StatisticDropdown;

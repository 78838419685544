import React from 'react';

import Select from 'react-dropdown-select';
import SelectItem from 'react-dropdown-select/lib/components/Item';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useMemo } from 'react';
import { groupBy } from 'lodash';

const FilterDropdown = ({ entityLabel, onFilterChange = () => {} }) => {
  const labeledDropdownRenderer = useMemo(() => dropdownRenderer(entityLabel), [
    entityLabel,
  ]);
  const options = useMemo(() => filterOptions(entityLabel), [entityLabel]);
  return (
    <>
      <FilterListIcon className="filter-icon" />
      <Select
        className={'filter-select display-none'}
        values={[options[0]]}
        placeholder="Filter"
        options={options}
        onChange={selected => {
          const [{ type, cutoffDays }] = selected;
          onFilterChange({ type, cutoffDays });
        }}
        valueField="index"
        dropdownRenderer={labeledDropdownRenderer}
        itemRenderer={({
          // eslint-disable-next-line no-unused-vars
          props: { itemRenderer, ...props },
          ...rest
        }) => (
          <SelectItem
            props={{ ...props, labelField: 'dropdownLabel' }}
            {...rest}
          />
        )}
      />
    </>
  );
};

function dropdownRenderer(entityLabel) {
  const renderer = ({ props, state, methods }) => {
    const { all, global, team } = groupBy(props.options, ({ type }) => type);
    return (
      <>
        {renderItems({ items: all, props, state, methods })}
        <div className="dropdown-heading">Global {entityLabel}</div>
        {renderItems({ items: global, props, state, methods })}
        <div className="dropdown-heading">Team {entityLabel}</div>
        {renderItems({ items: team, props, state, methods })}
      </>
    );
  };
  return renderer;
}

function renderItems({ items, props, state, methods }) {
  return items.map(item => (
    <SelectItem
      key={item.index}
      item={item}
      index={item.index}
      state={state}
      props={props}
      methods={methods}
    />
  ));
}
function filterOptions(entityLabel) {
  return [
    {
      label: `All ${entityLabel}`,
      dropdownLabel: `All ${entityLabel}`,
      cutoffDays: 0,
      type: 'all',
    },
    {
      label: `All Global ${entityLabel}`,
      dropdownLabel: 'All',
      cutoffDays: 0,
      type: 'global',
    },
    {
      label: '15 Days (Global)',
      dropdownLabel: 'Unused in 15 days',
      cutoffDays: 15,
      type: 'global',
    },
    {
      label: '30 Days (Global)',
      dropdownLabel: 'Unused in 30 days',
      cutoffDays: 30,
      type: 'global',
    },
    {
      label: '60 Days (Global)',
      dropdownLabel: 'Unused in 60 days',
      cutoffDays: 60,
      type: 'global',
    },
    {
      label: `All Team ${entityLabel}`,
      dropdownLabel: 'All',
      cutoffDays: 0,
      type: 'team',
    },
    {
      label: '15 Days (Team)',
      dropdownLabel: 'Unused in 15 days',
      cutoffDays: 15,
      type: 'team',
    },
    {
      label: '30 Days (Team)',
      dropdownLabel: 'Unused in 30 days',
      cutoffDays: 30,
      type: 'team',
    },
    {
      label: '60 Days (Team)',
      dropdownLabel: 'Unused in 60 days',
      cutoffDays: 60,
      type: 'team',
    },
  ].map((item, index) => ({ ...item, index }));
}

export default FilterDropdown;

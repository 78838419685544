import useKeepAPIAlive from '../hooks/useKeepAPIAlive';
import { useSessionState } from '../ducks/session';

const KeepAPIAlive = () => {
  const { apiURL } = useSessionState();
  useKeepAPIAlive(apiURL);
  return null;
};

export default KeepAPIAlive;

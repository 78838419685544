import React, { useState } from 'react';
import '../stylesheets/PollOptionRow.scss';
import Button from 'react-bootstrap/Button';

const PollOptionRow = ({ placeholder, onRemove, handleTextChange }) => {
  const [optionText, setOptionText] = useState('');

  return (
    <div className="poll-option-row-container">
      <input
        type="text"
        name="answer"
        maxLength="25"
        className="poll-option-text-field"
        placeholder={placeholder}
        value={optionText}
        onChange={event => {
          const text = event.target.value;
          setOptionText(text);
          handleTextChange(text);
        }}
      />
      <Button className="remove-option" onClick={onRemove}>
        Remove
      </Button>
    </div>
  );
};

export default PollOptionRow;

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../stylesheets/DropzoneWithPreview.scss';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const DropzoneWithPreview = ({ handleUpload }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/png', 'image/jpeg', 'image/gif', 'video/mp4'],
    onDrop: acceptedFiles => {
      handleUpload(acceptedFiles);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const preview = files.map(file => (
    <div className="preview" key={file.name}>
      {file.type.includes('image') ? (
        <img src={file.preview} className="filePreview" />
      ) : (
        <video className="dropzone-video-preview" controls>
          <source src={file.preview} type="video/mp4"></source>
        </video>
      )}
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {files.length === 0 ? (
          <div className="dropzone-instructions">
            <CloudUploadIcon />
            <p>Drop a media file here or click to browse</p>
            <p id="subtext">
              For optimal performance, we recommend uploading file smaller than
              30MB
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
      <aside className="previewContainer">{preview}</aside>
    </section>
  );
};

export default DropzoneWithPreview;

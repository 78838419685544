import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import { addSounds, replaceSounds, removeSounds } from '../ducks/sounds';

export const useSoundChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('SoundChannel', {
    add: ({ sounds }) => dispatch(addSounds(sounds)),
    update: ({ sounds }) => dispatch(replaceSounds(sounds)),
    remove: ({ sounds }) => dispatch(removeSounds(sounds)),
  });
};

import React from 'react';
import '../stylesheets/EmptyMessageList.scss';

const EmptyMessageList = () => {
  return (
    <div className="empty-message-list">
      <h2>There are no messages in this channel yet.</h2>
    </div>
  );
};

export default EmptyMessageList;

import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import {
  addTeamConfiguration,
  setTeamConfiguration,
  removeTeamConfiguration,
} from '../ducks/teamConfiguration';

export const useTeamConfigurationChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('TeamConfigurationChannel', {
    add: ({ team_configuration }) =>
      dispatch(addTeamConfiguration(team_configuration)),
    update: ({ team_configuration }) =>
      dispatch(setTeamConfiguration(team_configuration)),
    remove: ({ team_configuration }) =>
      dispatch(removeTeamConfiguration(team_configuration)),
  });
};

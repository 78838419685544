import { createSlice } from '@reduxjs/toolkit';
import { EntityAdapter } from './_util';
import { useSelector } from 'react-redux';

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'categories',
  initialState: { ...adapter.getInitialState(), selectedCategoryId: '' },
  reducers: {
    addCategories: adapter.addMany,
    replaceCategories: adapter.replaceMany,
    removeCategories: adapter.removeMany,
    selectCategory: (state, { payload: categoryId }) => {
      state.selectedCategoryId = categoryId;
    },
  },
});

export const {
  addCategories,
  replaceCategories,
  removeCategories,
  selectCategory,
} = slice.actions;

export const useSelectedCategory = () =>
  useSelector(({ categories: { selectedCategoryId } }) => selectedCategoryId);
export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;

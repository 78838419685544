import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import { addTrivia, replaceTrivia, removeTrivia } from '../ducks/trivia';

export const useTriviaChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('TriviaChannel', {
    add: ({ trivia }) => dispatch(addTrivia(trivia)),
    update: ({ trivia }) => dispatch(replaceTrivia(trivia)),
    remove: ({ trivia }) => dispatch(removeTrivia(trivia)),
  });
};

import React, { useState } from 'react';
import '../stylesheets/TriviaOptionRow.scss';
import Button from 'react-bootstrap/Button';

const TriviaOptionRow = ({
  placeholder,
  onRemove,
  handleTextChange,
  checked,
  onChecked,
}) => {
  const [optionText, setOptionText] = useState('');

  return (
    <div className="option-row-container">
      <input
        type="text"
        name="answer"
        maxLength="25"
        className="option-text-field"
        placeholder={placeholder}
        value={optionText}
        onChange={event => {
          const text = event.target.value;
          setOptionText(text);
          handleTextChange(text);
        }}
      />
      <div className="switch-container">
        <label>Correct answer</label>
        <label className="switch">
          <input
            name="correct"
            checked={checked}
            onChange={event => event.target.checked && onChecked()}
            type="checkbox"
          ></input>
          <span className="slider round"></span>
        </label>
      </div>
      <Button className="remove-option" onClick={onRemove}>
        Remove
      </Button>
    </div>
  );
};

export default TriviaOptionRow;

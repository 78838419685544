import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import { Provider } from 'react-redux';
import media from './media';
import session, { initializeSession } from './session';
import categories from './categories';
import interactions from './interactions';
import interactionsFeed from './interactionsFeed';
import teamConfiguration from './teamConfiguration';
import polls from './polls';
import trivia from './trivia';
import sounds from './sounds';
import statistics from './statistics';
import gameInstance from './gameInstance';
export { AsyncStatus } from './_util';

export const StoreProvider = ({
  apiURL,
  gameInstanceId,
  gameId,
  teamId,
  config,
  teamName,
  children,
}) => {
  return (
    <Provider
      store={createStore({
        apiURL,
        gameInstanceId,
        gameId,
        teamId,
        config,
        teamName,
      })}
    >
      {children}
    </Provider>
  );
};

const createStore = ({
  apiURL,
  gameInstanceId,
  gameId,
  teamId,
  config,
  teamName,
}) => {
  const store = configureStore({
    reducer: {
      media,
      session,
      categories,
      teamConfiguration,
      polls,
      trivia,
      sounds,
      interactions,
      interactionsFeed,
      statistics,
      gameInstance,
    },
  });

  store.dispatch(
    initializeSession({
      apiURL,
      gameInstanceId,
      gameId,
      teamId,
      config,
      teamName,
    })
  );
  return store;
};

import Snackbar from '@material-ui/core/Snackbar';
import React, { useEffect, useState } from 'react';
import { useInteractions } from '../ducks/interactions';
import Countdown from './Countdown';

const ToastAlert = () => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({});

  const interactionDetails = useInteractions();

  useEffect(() => {
    if (
      interactionDetails.title &&
      interactionDetails.id !== snackbarDetails.id
    ) {
      setSnackbarDetails({
        title: interactionDetails.title,
        id: interactionDetails.id,
        snackbarPreview: interactionDetails.snackbarPreview,
        isScoreboard: interactionDetails.interactionType === 'scoreboard',
      });
      setSnackbarVisible(true);
    } else if (interactionDetails.error) {
      setSnackbarDetails({
        error: interactionDetails.error,
        id: interactionDetails.id,
        snackbarPreview: interactionDetails.snackbarPreview,
      });
    }
  }, [
    interactionDetails.title,
    interactionDetails.id,
    snackbarDetails.id,
    interactionDetails.snackbarPreview,
    interactionDetails.error,
    interactionDetails.interactionType,
  ]);

  const onClose = () => {
    setSnackbarVisible(false);
  };

  const error = snackbarDetails.error,
    isScoreboard = snackbarDetails.isScoreboard;
  return (
    <Snackbar
      open={snackbarVisible}
      autoHideDuration={isScoreboard ? 15000 : 5000}
      onClose={(_, reason) => {
        if (reason !== 'clickaway' || !isScoreboard) {
          onClose();
        }
      }}
    >
      <div className={`snackbarContainer ${error ? 'fail' : ''}`}>
        {error ? (
          <p className="snackbar">
            <b>Failed to send interaction{!!error && `: ${error}`}</b>
          </p>
        ) : (
          <div className="snackbar-contents">
            {snackbarDetails.snackbarPreview && (
              <img
                className="snackbarImage"
                src={snackbarDetails.snackbarPreview}
              />
            )}
            <p className="snackbar">
              <b>Sent successfully: </b>
              {snackbarDetails.title}
            </p>
            {snackbarDetails.isScoreboard && (
              <div className="countdown-container">
                <Countdown />
                <button onClick={onClose}>Dismiss</button>
              </div>
            )}
          </div>
        )}
      </div>
    </Snackbar>
  );
};

export default ToastAlert;

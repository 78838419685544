import axios from 'axios';
import { useEffect, useRef } from 'react';

const useKeepAPIAlive = apiURL => {
  const interval = useRef(null);

  const pingAPI = async () => {
    const pingURL = `${apiURL}/v1/api/health/healthy`;
    try {
      await axios.get(pingURL);
    } catch (e) {
      console.log(e);
    }
  };

  const keepAPIAlive = (duration) => {
    interval.current && clearInterval(interval.current);
    interval.current = setInterval(() => {
      pingAPI();
    }, duration);
  };

  useEffect(() => {
    keepAPIAlive(5000);
    return () => {
      interval && clearInterval(interval.current);
    };
  });

  return null;
};

export default useKeepAPIAlive;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createGameTrivia } from '../ducks/trivia';
import '../stylesheets/CreateTriviaModal.scss';
import TriviaOptionRow from './TriviaOptionRow';
import { unwrapResult } from '@reduxjs/toolkit';

const CreateTriviaModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const [triviaPrompt, setTriviaPrompt] = useState('');
  const [checkedOptionIndex, setCheckedOptionIndex] = useState();
  const [triviaOptionsList, setTriviaOptionsList] = useState([
    { answer: 'Option', correct: false },
    { answer: 'Option', correct: false },
  ]);

  const submit = () => {
    if (validateForm()) {
      dispatch(
        createGameTrivia({
          prompt: triviaPrompt,
          options: triviaOptionsList,
        })
      )
        .then(unwrapResult)
        .then(() => {
          handleClose();
          resetForm();
        })
        .catch(() => showErrorMessage());
    } else {
      alert('Please fill out the rest of the form');
    }
  };

  const validateForm = () => {
    if (triviaPrompt !== '') {
      for (var i = 0; i < triviaOptionsList.length; i++) {
        if (triviaOptionsList[i].correct === true) {
          return true;
        }
      }
    }
  };

  const resetForm = () => {
    setTriviaPrompt('');
    setTriviaOptionsList([
      { answer: 'Option', correct: false },
      { answer: 'Option', correct: false },
    ]);
    setCheckedOptionIndex();
  };

  const showErrorMessage = () => {
    alert('Trivia creation failed... Please try again!');
  };

  const addOptionInput = () => {
    if (triviaOptionsList.length < 4) {
      setTriviaOptionsList([
        ...triviaOptionsList,
        { answer: 'Option', correct: false },
      ]);
    }
  };

  const removeOption = index => {
    if (triviaOptionsList.length > 2) {
      const list = [...triviaOptionsList];
      list.splice(index, 1);
      setTriviaOptionsList(list);
    }
  };

  const handleTextChange = (newText, index) => {
    const list = [...triviaOptionsList];
    list[index]['answer'] = newText;
    setTriviaOptionsList(list);
  };

  const handleCheckboxChange = index => {
    const list = [...triviaOptionsList];
    list[index]['correct'] = true;
    setCheckedOptionIndex(index);
    setTriviaOptionsList(list);
  };

  return (
    <Modal id="trivia" show={show} centered size="xl" onHide={() => {}}>
      <div className="modal-media-details-form">
        <h3>Create Trivia</h3>
        <input
          type="text"
          name="title"
          className="media-text-field"
          maxLength="100"
          placeholder="Question"
          value={triviaPrompt}
          onChange={() => setTriviaPrompt(event.target.value)}
        />
        <div className="options-list">
          {triviaOptionsList.map((option, index) => {
            return (
              <TriviaOptionRow
                checked={index === checkedOptionIndex}
                onChecked={() => handleCheckboxChange(index)}
                placeholder={option.answer}
                key={index}
                onRemove={() => removeOption(index)}
                handleTextChange={option => handleTextChange(option, index)}
              />
            );
          })}
          {triviaOptionsList.length < 4 && (
            <Button className="add-option-button" onClick={addOptionInput}>
              Add Option
            </Button>
          )}
        </div>
        <Button className="create-trivia" onClick={submit}>
          Create Trivia
        </Button>
        <Button
          className="cancel-trivia"
          onClick={() => {
            handleClose();
            resetForm();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default CreateTriviaModal;

import React, { useState } from 'react';
import { DirectUploadProvider } from 'react-activestorage-provider';
import { useDispatch } from 'react-redux';
import { createMedia } from '../ducks/media';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../stylesheets/AddGameMediaModal.scss';
import { useSessionState } from '../ducks/session';
import { unwrapResult } from '@reduxjs/toolkit';
import { Multiselect } from 'multiselect-react-dropdown';
import DropzoneWithPreview from './DropzoneWithPreview';
import ConfirmModal from './ConfirmModal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';

const AddGameMediaModal = ({ show, handleClose, categories }) => {
  const { gameInstanceId } = useSessionState();
  const dispatch = useDispatch();
  const [signedAssetId, setSignedAssetId] = useState();
  const [assetTitle, setAssetTitle] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [repeatChecked, setRepeatChecked] = useState(false);
  const [over21Checked, setOver21Checked] = useState(false);
  const [errorPresented, setErrorPresented] = useState(false);
  const [showDangerAlert, setShowDangerAlert] = useState(false);
  const [uploadAttempt, setUploadAttempt] = useState(0);
  const incrementUploadAttempt = () => setUploadAttempt(prev => prev + 1);

  const submit = () => {
    const categoryIds = selectedCategories.map(item => item.id);
    if (validateForm()) {
      dispatch(
        createMedia({
          asset: signedAssetId,
          title: assetTitle,
          over21: over21Checked,
          repeat: repeatChecked,
          gameInstanceId,
          categoryIds: categoryIds,
        })
      )
        .then(unwrapResult)
        .then(() => {
          handleClose();
          resetForm();
        })
        .catch(() => showErrorMessage());
    } else {
      alert('Please fill out all details for the form.');
    }
  };

  const validateForm = () => {
    return assetTitle !== '' && signedAssetId;
  };

  const resetForm = () => {
    setAssetTitle('');
    setOver21Checked(false);
    setRepeatChecked(false);
    setSignedAssetId();
    setSelectedCategories([]);
    setShowDangerAlert(false);
  };

  const showErrorMessage = () => {
    setErrorPresented(true);
  };

  const onCategoryClicked = selectedList => {
    setSelectedCategories(selectedList);
  };

  const dismissErrorMessage = () => setErrorPresented(false);

  return (
    <Modal show={show} dialogClassName="modal-700" centered onHide={() => {}}>
      <div className="modal-dropzone">
        <ConfirmModal
          title="Add game media failed... Please try again."
          confirmButtonTitle="Dismiss"
          cancelButtonTitle="Exit Add Game Media"
          onConfirm={dismissErrorMessage}
          show={errorPresented}
          handleClose={() => {
            dismissErrorMessage();
            handleClose();
            resetForm();
          }}
        />
        <DirectUploadProvider
          onSuccess={signedIds => {
            setTimeout(() => setSignedAssetId(signedIds[0]), 0);
          }}
          render={({ handleUpload, uploads }) => (
            <div className="dropzone-box">
              <DropzoneWithPreview
                key={uploadAttempt}
                handleUpload={handleUpload}
              />
              {uploads.map((upload, index) => {
                switch (upload.state) {
                  case 'error':
                    incrementUploadAttempt();
                    setShowDangerAlert(true);
                    break;
                  default:
                    setShowDangerAlert(false);
                    return (
                      <ProgressBar
                        className="upload-progress-bar"
                        key={index}
                        label={`${
                          upload.progress ? Math.round(upload.progress) : 0
                        }%`}
                        now={Math.round(upload.progress)}
                      />
                    );
                }
              })}
              <Alert
                className="danger-alert"
                show={showDangerAlert}
                variant="danger"
              >
                Error uploading file. Please try again.
              </Alert>
            </div>
          )}
        />
      </div>
      <div className="modal-media-details-form">
        <input
          type="text"
          name="title"
          className="media-text-field"
          placeholder="Media title"
          value={assetTitle}
          onChange={event => setAssetTitle(event.target.value)}
        />
        <div className="one-menu">
          <Multiselect
            avoidHighlightFirstOption={true}
            placeholder="Category: None"
            hidePlaceholder={true}
            options={categories}
            selectedValues={selectedCategories}
            onSelect={onCategoryClicked}
            onRemove={onCategoryClicked}
            displayValue="name"
            showCheckbox={true}
          />
        </div>
        <div className="toggle-row">
          <div className="switch-container">
            <label>Can repeat</label>
            <label className="switch">
              <input
                name="correct"
                checked={repeatChecked}
                onChange={event => setRepeatChecked(event.target.checked)}
                type="checkbox"
              ></input>
              <span className="slider round"></span>
            </label>
          </div>
          <div className="switch-container">
            <label>Must be over 21</label>
            <label className="switch">
              <input
                name="correct"
                checked={over21Checked}
                onChange={event => setOver21Checked(event.target.checked)}
                type="checkbox"
              ></input>
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <Button className="ht-button add-media" onClick={submit}>
          Add Game Media
        </Button>
        <Button
          className="ht-button cancel-media"
          onClick={() => {
            handleClose();
            resetForm();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default AddGameMediaModal;

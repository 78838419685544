import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import {
  addGameInstance,
  setGameInstance,
  removeGameInstance,
} from '../ducks/gameInstance';

export const useGameInstanceChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('GameInstanceChannel', {
    add: ({ game_instance }) => dispatch(addGameInstance(game_instance)),
    update: ({ game_instance }) => dispatch(setGameInstance(game_instance)),
    remove: ({ game_instance }) => dispatch(removeGameInstance(game_instance)),
  });
};

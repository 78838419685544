import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSessionState } from '../ducks/session';

const Router = ({ children }) => {
  const { gameInstanceId } = useSessionState();
  return (
    <BrowserRouter basename={`/app/dashboard/${gameInstanceId}`}>
      {children}
    </BrowserRouter>
  );
};

export default Router;

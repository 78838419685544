import React from 'react';
import Select from 'react-dropdown-select';
import '../stylesheets/DropdownMenu';

const DropdownMenu = ({ dropdownText, options, onChange, selectionMade }) => {
  return (
    <Select
      multi={false}
      className={`bg-grey ${selectionMade ? 'display-none' : ''}`}
      placeholder={dropdownText}
      options={options}
      onChange={onChange}
    />
  );
};

export default DropdownMenu;

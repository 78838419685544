import React, { useState, useEffect } from 'react';
import '../stylesheets/Countdown.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(15);

  useEffect(() => {
    const timer =
      timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <div className="countdown-timer">
      <CircularProgress variant="static" value={(timeLeft / 15) * 100} />
    </div>
  );
};

export default Countdown;

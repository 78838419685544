import React from 'react';
import { useMessageInput } from 'stream-chat-react';

const ChatInput = props => {
  const messageInput = useMessageInput(props);

  return (
    <div className="chat-input-container">
      <form onSubmit={messageInput.handleSubmit}>
        <input
          type="text"
          placeholder="Enter your message!"
          onChange={messageInput.handleChange}
          value={messageInput.text}
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ChatInput;

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const slice = createSlice({
  name: 'gameInstance',
  initialState: { instance: null },
  reducers: {
    addGameInstance: (state, { payload: instance = null }) => {
      state.instance = instance;
    },
    setGameInstance: (state, { payload: instance = null }) => {
      state.instance = instance;
    },
    removeGameInstance: state => {
      state.instance = null;
    },
  },
});

export const {
  addGameInstance,
  setGameInstance,
  removeGameInstance,
} = slice.actions;

export const useGameState = () =>
  useSelector(state => state.gameInstance.instance?.state);

export const useGameId = () =>
  useSelector(state => state.gameInstance.instance?.game_id);

export default slice.reducer;

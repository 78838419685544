import { createSlice } from '@reduxjs/toolkit';
import { EntityAdapter } from './_util';

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'statistics',
  initialState: adapter.getInitialState(),
  reducers: {
    addStatistics: adapter.addMany,
    replaceStatistics: adapter.replaceMany,
    removeStatistics: adapter.removeMany,
  },
});

export const {
  addStatistics,
  replaceStatistics,
  removeStatistics,
} = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;

import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import SoundEffectButton from './SoundEffectButton';
// import StatisticDropdown from './StatisticDropdown';
import SearchBar from './SearchBar';
import InteractionsFeed from './InteractionsFeed';
import { selectors as soundSelectors } from '../ducks/sounds';
import { useSelector } from 'react-redux';
import '../stylesheets/SoundEffectsSidebar.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { triggerInteraction } from '../ducks/interactions';
import { useSessionState } from '../ducks/session';
import { useDispatch } from 'react-redux';
// import { selectors as statsSelectors } from '../ducks/statistics';

const SoundEffectsSidebar = () => {
  const sounds = useSelector(soundSelectors.selectAll).filter(
    sound => !!sound.last_published_at
  );
  const [searchQuery, setSearchQuery] = useState('');
  const onSearchQueryChanged = e => setSearchQuery(e.target.value);
  const [repeatingSoundDispatched, setRepeatingSoundDispatched] = useState(
    false
  );

  // const stats = useSelector(statsSelectors.selectAll);

  // const favoriteStats = stats.filter(stat => stat.favorite);

  const dispatch = useDispatch();

  const { gameInstanceId } = useSessionState();

  const dynamicSearch = () => {
    return searchQuery.length === 0
      ? favoriteSounds
      : sounds.filter(sound =>
          sound.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
  };

  const favoriteSounds = sounds.filter(sound => sound.favorite);

  return (
    <div className="sidebar-container">
      <Paper className="sound-effects-sidebar">
        <InteractionsFeed />
        <div className="sound-effects-container">
          <div className="sound-effects-titlebar">
            <h3>Audio</h3>
            <button
              onClick={() => {
                dispatch(
                  triggerInteraction({
                    gameInstanceId,
                    interactionInfo: {
                      type: 'stop-sound',
                      title: 'Stop Sound',
                    },
                  })
                ).then(
                  ({ error }) => !error && setRepeatingSoundDispatched(false)
                );
              }}
              className={`stop-sound-button ${
                repeatingSoundDispatched ? 'active' : ''
              }`}
            >
              <HighlightOffIcon />
              <p>Stop</p>
            </button>
          </div>
          <SearchBar onSearchQueryChanged={onSearchQueryChanged} />
          <div className="scroll">
            {dynamicSearch().map((sound, index) => (
              <SoundEffectButton
                sound={sound}
                key={index}
                onRepeatingSound={isRepeating =>
                  setRepeatingSoundDispatched(isRepeating)
                }
              />
            ))}
          </div>
        </div>
        {/*<div className="favorite-stats-container">*/}
        {/*  <h3>Favorite Stats</h3>*/}
        {/*  <div className="stats-scroll">*/}
        {/*    {favoriteStats.map((stat, index) => (*/}
        {/*      <StatisticDropdown key={index} stat={stat} />*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Paper>
    </div>
  );
};

export default SoundEffectsSidebar;

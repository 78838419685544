import React from 'react';
import useInteractionsFeedState from '../hooks/useInteractionsFeedState';

import '../stylesheets/InteractionsFeed.scss';

const formatCountdownString = (current, duration) => {
  current = Math.round(current || 0);
  duration = Math.round(duration || 0);
  return `${duration - current} / ${duration}s`;
};

const InteractionsFeed = () => {
  const {
    lastEventLabel,
    visualAssetTitle,
    visualAssetSrc,
    repeatVideo,
    showImage,
    showVideoTimeData,
    videoTimeData,
    showHeartbeat,
    showCachedScoreboard,
    audioAssetSrc,
    audioAssetTitle,
    repeatAudio,
    showAudioTimeData,
    audioTimeData,
    audioPlayer,
    videoPlayer,
    showSTM,
    lastScoreboardType,
  } = useInteractionsFeedState();

  return (
    <div className="interactions-feed-container ">
      <audio
        ref={audioPlayer}
        src={audioAssetSrc}
        loop={repeatAudio}
        autoPlay
        muted
      />
      {
        <video
          className="interactions-feed-visual-media"
          ref={videoPlayer}
          type="video/mp4"
          src={visualAssetSrc}
          loop={repeatVideo}
          autoPlay
          muted
        />
      }
      {showImage && (
        <img className="interactions-feed-visual-media" src={visualAssetSrc} />
      )}
      <div className="interactions-feed-overlay">
        {showSTM && <p className="interactions-feed-stm-label">STM ON</p>}
        {!showHeartbeat && (
          <h3 className="interactions-feed-last-event-label">
            {lastEventLabel}
          </h3>
        )}
        {(showCachedScoreboard || showHeartbeat) && (
          <h3 className="interactions-feed-heartbeat">
            {showCachedScoreboard
              ? `CACHED ${lastScoreboardType} Scoreboard`
              : 'Heartbeat'}
          </h3>
        )}
        <div className="interactions-feed-overlay-section">
          {visualAssetTitle && (
            <p className="interactions-feed-audio-label">{`👁️ ${visualAssetTitle}`}</p>
          )}
          {showVideoTimeData && (
            <p className="interactions-feed-visual-label">
              {repeatVideo ? 'repeat' : ''}{' '}
              {formatCountdownString(
                videoTimeData.currentTime,
                videoTimeData.duration
              )}
            </p>
          )}
        </div>
        <div className="interactions-feed-overlay-section">
          {audioAssetTitle && (
            <p className="interactions-feed-audio-label">{`🔊 ${audioAssetTitle}`}</p>
          )}
          {showAudioTimeData && (
            <p className="interactions-feed-audio-label">
              {repeatAudio ? 'repeat' : ''}{' '}
              {formatCountdownString(
                audioTimeData.currentTime,
                audioTimeData.duration
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InteractionsFeed;

import axios from 'axios';

const api = axios.create({
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
  xsrfCookieName: 'CSRF-Token',
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true,
});

export const post = (url, data) =>
  api.post(url, data).then(response => response.data);

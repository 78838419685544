import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'interactionsFeed',
  initialState: {
    connectionState: '',
    gameTimeMessage: null,
  },
  reducers: {
    setConnectionState: (state, { payload: { connectionState } }) => {
      state.connectionState = connectionState;
    },
    setGameTimeMessage: (state, { payload: { event, data } }) => {
      state.gameTimeMessage = { event, data };
    },
  },
});

export const { setConnectionState, setGameTimeMessage } = slice.actions;

export default slice.reducer;

import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import { interactionSent } from '../ducks/interactions';

export const useInteractionChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('InteractionChannel', {
    add: ({ interactions: [interaction] }) => {
      dispatch(interactionSent(interaction));
    },
  });
};

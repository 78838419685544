import React from 'react';
import { useDeleteHandler, Avatar } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import { useSessionState } from '../ducks/session';
import { post } from '../services/cms_api_client';

const ChatMessage = props => {
  const { message } = props;
  const { text, user } = message;

  const { config } = useSessionState();
  const { adminUserEmail } = config;

  const handleDelete = useDeleteHandler(message);

  const handleBan = message => {
    post('/api/moderation/ban', {
      target_id: message.user.id,
      user_id: adminUserEmail,
    }).then(() => alert('User was banned!'));
  };

  const handleUnban = message => {
    post('/api/moderation/unban', {
      target_id: message.user.id,
      user_id: adminUserEmail,
    }).then(() => alert('User was unbanned!'));
  };

  const handleShadowBan = message => {
    post('/api/moderation/shadowban', {
      target_id: message.user.id,
      user_id: adminUserEmail,
    }).then(() => alert('User was shadowbanned!'));
  };

  const handleUnshadowBan = message => {
    post('/api/moderation/unshadowban', {
      target_id: message.user.id,
      user_id: adminUserEmail,
    }).then(() => alert('User was un-shadowbanned!'));
  };

  return (
    <div className="chat-container">
      {message.deleted_at ? (
        <div style={{ marginLeft: 20 }}>{'This message was deleted'}</div>
      ) : (
        <>
          <div className="message-details">
            <Avatar image={user.image} size={50} />
            <div className="user-details">
              <p id="username">{user.name}</p>
              <p>{text}</p>
              {message.shadowed && (
                <p id="shadow-notice">
                  This message was posted while shadowbanned.
                </p>
              )}
            </div>
          </div>

          <div className="btn-group shortcuts" role="group">
            <button
              id="stream-dropdown"
              className="btn dropdown-toggle shortcut-dropdown"
              data-toggle="dropdown"
            >
              <p>Mod Tools</p>
            </button>
            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <button className="dropdown-item" onClick={handleDelete}>
                <p>Delete</p>
              </button>
              <>
                {!user.banned ? (
                  <>
                    <button
                      className="dropdown-item"
                      onClick={() => handleBan(message)}
                    >
                      Ban
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="dropdown-item"
                      onClick={() => handleUnban(message)}
                    >
                      Unban
                    </button>
                  </>
                )}
              </>

              <>
                {!user.banned && !message.shadowed && (
                  <button
                    className="dropdown-item"
                    onClick={() => handleShadowBan(message)}
                  >
                    Shadow Ban
                  </button>
                )}
                {!user.banned && message.shadowed && (
                  <button
                    className="dropdown-item"
                    onClick={() => handleUnshadowBan(message)}
                  >
                    Remove Shadow Ban
                  </button>
                )}
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatMessage;

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const slice = createSlice({
  name: 'session',
  initialState: { gameInstanceId: null, apiURL: null },
  reducers: {
    initializeSession(
      state,
      { payload: { apiURL, gameInstanceId, teamId, gameId, config, teamName } }
    ) {
      state.gameInstanceId = gameInstanceId;
      state.teamId = teamId;
      state.gameId = gameId;
      state.config = config;
      state.teamName = teamName;
      state.apiURL = apiURL;
    },
  },
});

export const { initializeSession } = slice.actions;

export const useSessionState = () => useSelector(({ session }) => session);

export default slice.reducer;

import consumer from './consumer';
import { useEffect } from 'react';
import { useSessionState } from '../ducks/session';

export const useModelChannel = (channelName, { add, update, remove }) => {
  const { gameInstanceId } = useSessionState();
  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: channelName, game_instance_id: gameInstanceId },
      {
        connected() {
          console.info(`Connected to ${channelName}`);
        },

        disconnected() {
          console.info(`Disconnected from ${channelName}`);
        },

        received({ action, ...rest }) {
          console.info(`Received "${action}" on ${channelName}`);
          switch (action) {
            case 'add':
              add?.(rest);
              return;
            case 'update':
              update?.(rest);
              return;
            case 'remove':
              remove?.(rest);
              return;
            default:
              console.error(`Unrecognized action ${action} on ${channelName}`);
          }
        },
      }
    );

    return () => subscription.unsubscribe();
  });
};

import React from 'react';
import '../stylesheets/SelectCategoryLink';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelectedCategory } from '../ducks/categories';

const SelectCategoryLink = ({ navTitle, onClick, categoryId }) => {
  const selectedCategory = useSelectedCategory();
  const history = useHistory();
  const location = useLocation();
  return (
    <div className="category-link">
      <button
        className={`c-button ${
          categoryId === selectedCategory ? 'active' : ''
        }`}
        onClick={() => {
          onClick();
          if (!location.pathname.endsWith('media')) {
            history.push('/media');
          }
        }}
      >
        {navTitle}
      </button>
    </div>
  );
};

export default SelectCategoryLink;

// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

import { useMediaChannel } from './media_channel';
import { useCategoryChannel } from './category_channel';
import { useTeamConfigurationChannel } from './team_configuration_channel';
import { usePollChannel } from './poll_channel';
import { useTriviaChannel } from './trivia_channel';
import { useSoundChannel } from './sound_channel';
import { useStatisticChannel } from './statistic_channel';
import { useInteractionChannel } from './interaction_channel';
import { useGameInstanceChannel } from './game_instance_channel';

export const useChannels = () => {
  useMediaChannel();
  useCategoryChannel();
  useTeamConfigurationChannel();
  usePollChannel();
  useTriviaChannel();
  useSoundChannel();
  useStatisticChannel();
  useInteractionChannel();
  useGameInstanceChannel();
};

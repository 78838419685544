export const buildDropdownOptions = item => {
  const optionsList = [];

  if (
    item.statistic.option1_name !== null &&
    item.statistic.option1_name !== '' &&
    item.option1_values
  ) {
    optionsList.push(
      buildOptions(item.statistic.option1_name, item.option1_values)
    );
  }

  if (
    item.statistic.option2_name !== null &&
    item.statistic.option2_name !== '' &&
    item.option2_values
  ) {
    optionsList.push(
      buildOptions(item.statistic.option2_name, item.option2_values)
    );
  }

  return optionsList;
};

const buildOptions = (label, values) => {
  const options = values.map(option => ({
    value: option.id,
    id: option.id,
    label: option.name,
    key: toCamelCase(label),
  }));
  return {
    name: label,
    options: options,
  };
};

const toCamelCase = s => {
  const [first, ...rest] = s.split(' ');
  return (
    first.toLowerCase() +
    rest
      .map(p => p.slice(0, 1).toUpperCase() + p.slice(1).toLowerCase())
      .join('')
  );
};

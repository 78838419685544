import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../stylesheets/ConfirmModal.scss';

const ConfirmModal = ({
  title,
  confirmButtonTitle,
  cancelButtonTitle,
  onConfirm,
  handleClose,
  show,
}) => {
  return (
    <Modal show={show} centered onHide={handleClose}>
      <div className="modal-box">
        <h3>{title}</h3>
        <div className="row">
          <Button className="confirm-button" onClick={onConfirm}>
            {confirmButtonTitle}
          </Button>
          <Button className="cancel-button" onClick={handleClose}>
            {cancelButtonTitle !== undefined ? cancelButtonTitle : 'Cancel'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
